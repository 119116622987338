<template>
  <v-app theme="dark">
    <v-main>
      <v-app-bar v-if="token">
        <v-app-bar-title>Crispin Data+Analytics / Creator</v-app-bar-title>
        <template #append>
          <v-menu open-on-hover>
            <template #activator="{props}">
              <v-app-bar-nav-icon v-bind="props"/>
            </template>
            <v-list density="compact" nav>
              <v-list-item v-for="item in nav_menu_items" :key="item.title" @click="item.click">
                <template #append>
                  <v-icon :icon="item.icon" size="x-small"/>
                </template>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-app-bar>
      <Suspense>
        <router-view/>
      </Suspense>
      <Loading/>
    </v-main>
  </v-app>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

import Loading from "@/components/Loading";

const store = useStore();
const router = useRouter();
const token = computed(() => store.state.auth.token);

const nav_menu_items = computed(() => {
  const items = []
  if (store.state.auth.data.permissions.tools.includes('brands')) {
    items.push({text: 'Brands', icon: 'fa fa-building', click: () => router.push({name: 'brands'})})
  }
  if (store.state.auth.data.permissions.tools.includes('users')) {
    items.push({text: 'Users', icon: 'fa fa-user', click: () => router.push({name: 'users'})})
  }
  items.push({text: 'Log Out', icon: 'fa fa-sign-out', click: () => store.dispatch('auth/logout')})
  return items
})
</script>


<style lang="less">
</style>